import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import ClaimsHistoryBaseComponent, { ClaimsHistoryData } from 'components/FormPage/claimsHistory';
import { DriverType } from 'types/forms';

type ClaimsHistoryProps = {
  data: {
    csAddDriverClaimsHistoryTemplate: ClaimsHistoryData;
  };
  location: Location;
};

const ClaimsHistoryPage: React.FC<ClaimsHistoryProps> = ({ data, location }) => (
  <ClaimsHistoryBaseComponent
    data={data.csAddDriverClaimsHistoryTemplate}
    location={location}
    driverType={DriverType.PERMANENT}
  />
);

export default ClaimsHistoryPage;

export const query = graphql`
  query {
    csAddDriverClaimsHistoryTemplate(driver_type: { eq: "Permanent" }) {
      meta_title
      heading
    }
  }
`;
